/* ongoing, list */

.mission {
    max-width: 320px;
    height: 30;
}

.ongoing-text-bottom {
    margin-top: 75px;
}

.progress-bar {
    background-color: #30A9DE;
}

/* request-settlement */

.background-main-color {
    border-radius: 5px;
    background-color: #30A9DE;
    color: #fff;
    cursor: pointer;
}

/* concept */

h6 {
    font-size: 18px;
}

.light-font {
    font-size: 14px;
    font-weight: 400;
    color: #566270;
}
.study-subject-container {
    width: 1000px;
    margin: 0 auto;
}
.cocept-input {
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    border: 0px;
    border-bottom: 1px solid #ced4da;
}
.cocept-input-sm {
    border: 1px solid #ced4da;
}
.border-light-color {
    border: 1px solid #ced4da;
}
.border-dark-color {
    border: 1px solid #6c757d;
}

.search-result-box {
    border: 1px solid #ced4da;
}

.search-result-box .item{
    font-size: 16px;
    border-bottom: 1px solid #ced4da;
}

.search-result-box .item:last-child {
    border-bottom: 0px
}

.search-concept-result {
    border: 1px solid #ced4da;
}

.select-related-majors-table th {
    width: 130px;
}

.mission-list-pen-container {
    justify-content: flex-end;
    color: #30A9DE;
    cursor: pointer;
}
.mission-list-pen-container span {
    margin-right: 5px;
}

.major-add-btn {
    padding: 1rem;
}

.major-add-btn svg {
    padding-bottom: 5px;
}

.related-major-modal-title {
    width: 200px;
    color: #30A9DE;
    font-weight: 700;
}

.selected-major-category {
    display: flex;
    flex-direction: row;
    color: #30A9DE;
}

.selected-major-delete-btn {
    height: 40px;
    margin-top: 4px;
}

.help-tip {
    position: absolute;
    top: 7px;
    right: 0px;
    text-align: center;
    background-color: #30A9DE;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    font-size: 14px;
    line-height: 26px;
    cursor: default;
}

.help-tip:before {
    content: '?';
    font-weight: bold;
    color: #fff;
}

.help-tip:hover p {
    display: block;
    transform-origin: 100% 0%;
    -webkit-animation: fadeIn 0.3s ease-in-out;
    animation: fadeIn 0.3s ease-in-out;
}

.help-tip p {
    display: none;
    text-align: left;
    background-color: #bcdbea;
    padding: 20px;
    width: 320px;
    position: absolute;
    border-radius: 3px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    top: -80px;
    right: -4px;
    color: #000;
    font-size: 13px;
    line-height: 1.4;
}
.help-tip span {
    font-weight: 700;
}
.help-tip .long-p {
    display: none;
    text-align: left;
    background-color: #bcdbea;
    padding: 20px;
    width: 320px;
    position: absolute;
    border-radius: 3px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    top: -100px;
    right: -4px;
    color: #000;
    font-size: 13px;
    line-height: 1.4;
}
.help-tip p:before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-top-color: #ced4da;
    right: 10px;
    bottom: -12px;

}

.help-tip p:after {
    width: 100%;
    height: 40px;
    content: '';
    position: absolute;
    top: -40px;
    left: 0;

}

.help-tip-home {
    position: absolute;
    top: 6px;
    right: 8px;
    text-align: center;
    background-color: #ccc;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    font-size: 14px;
    line-height: 22px;
    cursor: default;
}

.help-tip-home:before {
    content: '?';
    font-weight: bold;
    color: #fff;
}

.help-tip-home:hover p {
    display: block;
    transform-origin: 100% 0%;
    -webkit-animation: fadeIn 0.3s ease-in-out;
    animation: fadeIn 0.3s ease-in-out;
}

.help-tip-home p {
    display: none;
    text-align: left;
    background-color: #777;
    padding: 20px;
    width: 320px;
    position: absolute;
    border-radius: 3px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    top: -80px;
    right: -4px;
    color: #FFF;
    font-size: 13px;
    line-height: 1.4;
}

.help-tip-home .short-p {
    display: none;
    text-align: left;
    background-color: #777;
    padding: 20px;
    width: 320px;
    position: absolute;
    border-radius: 3px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    top: -63px;
    right: -4px;
    color: #FFF;
    font-size: 13px;
    line-height: 1.4;
}

.help-tip-home p:before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-top-color: #777;
    right: 10px;
    bottom: -12px;

}

.help-tip-home p:after {
    width: 100%;
    height: 40px;
    content: '';
    position: absolute;
    top: -40px;
    left: 0;

}

.ongoing-mission-container {
    height: 300px;
    overflow-y: scroll;
}
.ongoing-mission-container::-webkit-scrollbar-thumb {background-color: #ced4da; border-radius: 5px;}
.ongoing-mission-container::-webkit-scrollbar-track {
    background-color: #fff;
}
.ongoing-mission-container::-webkit-scrollbar {
    width: 5.2px;
}


@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
        transform: scale(0.6);
    }

    100% {
        opacity: 100%;
        transform: scale(1);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 100%;
    }
}

.study-subject-naver-btn {
    background: #03CF5D;
    display: flex;
    color: #fff;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-weight: 600;
    cursor: pointer;
}
.study-subject-naver-btn:hover {
    color: #fff;
    opacity: 0.8;
}
.study-subject-ex-btn {
    color: #30A9DE;
    font-family: 'Spoqa Han Sans Neo',
    'sans-serif';
    border: 2px solid #30A9DE;
    border-radius: 7px;
    padding: 10px 20px 6px;
    width: 125px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
}

/* Test */
.ra-test-email-container {
    display: flex;
    align-items: center;
}
.ra-test-email-descripton {
    line-height: 21px;
    margin-bottom: 8px;
}
.ra-test-email-descripton span {
    font-weight: 700;
}
.ra-test-input {
    width: 355px;
    padding: 15px;
    border: 0;
    display: block;
    margin-top: 8px;
    background-color: rgb(252, 252, 252);
    box-shadow: rgba(46, 46, 51, 0.08) 0px 0px 0px 1px inset,
        rgba(0, 0, 0, 0.04) 0px 1px 1px 0px inset;
}
.ra-test-input.email {
    width: 260px;
    padding: 15px;
    border: 0;
    display: block;
    margin-top: 6px;
    background-color: rgb(252, 252, 252);
    box-shadow: rgba(46, 46, 51, 0.08) 0px 0px 0px 1px inset,
        rgba(0, 0, 0, 0.04) 0px 1px 1px 0px inset;
}
.ra-test-select-container {
    width: 150px;
}
.ra-test-email-container button {
    margin-left: 15px;
    margin-top: 4px;
}
.ra-test-grade-select-container {
    width: 150px;
    margin-left: 20px;
}

.ra-test-agree-container {
    padding: 30px 40px 10px 30px;
    border: 1px solid #c4c4c4;
    margin-top: 15px;
    margin-bottom: 15px;
}
.ra-test-agree-line {
    margin-bottom: 15px;
    line-height: 20px;
}
.ra-test-agree-line span {
    color: red;
    font-weight: 600;
}
.ra-test-agree-input-container {
    margin-top: 5px;
    text-align: right;
}
.ra-test-description {
    font-weight: 600;
}
.ra-test-description span {
    color: #30A9DE;
}

.ra-test-tutorial-container {
    border: 1px solid #c4c4c4;
    margin-bottom: 15px;
}
.ra-test-tutorial-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px
}
.ra-test-tutorial-title-container span {
    font-size: 21px;
    font-weight: 600;
}
.ra-test-tutorial-title-container.bg-gray {
    background: #f4f5f6;
}
.ra-test-tutorial-content-container {
    padding: 20px;
    border-top: 1px solid #c4c4c4;
}
.ra-test-tutorial-content {
    margin-bottom: 15px;
    line-height: 19px;
}
.ra-test-tutorial-content span {
    color: red;
    font-weight: 600;
}
.ra-test-tutorial-content bold {
    font-weight: 600;
}
.ra-test-tutorial-content.bold {
    font-weight: 600;
}
.ra-test-tutorial-ex-container {
    color: rgb(84, 84, 84);
    background-color: rgba(239, 239, 239, 0.3);
    line-height: 24px;
    margin-bottom: 15px;
}
.ra-test-mission-keyword-container {
    display: flex;
}
.ra-test-mission-keyword {
    width: 22% !important;
}
/* List */

.ra-list-mission-container {
    border: 1px solid #c4c4c4;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 28px;
}
.ra-list-mission-box {
    display: flex;
    width: 60%;
}
.ra-list-mission-box.join-page {
    display: flex;
    width: 85%;
}
.ra-list-mission-container.join-page {
    cursor: pointer;
}
.ra-list-mission-container.join-page:hover {
    background: #f4f5f6;
}
.ra-list-mission-img-container {
    width: 90px;
}
.ra-list-mission-img {
    width: 100%;
}
.ra-list-mission-body {
    padding: 20px 30px;
}
.ra-list-mission-body-title {
    font-size: 19px;
    font-weight: 600;
}
.ra-list-mission-body-bottom {
    display: flex;
    align-items: center;
}
.ra-list-mission-body-tag {
    border: 1px solid #30A9DE;
    color: #30A9DE;
    padding: 5px 38px;
    border-radius: 5px;
    margin-right: 15px;
    margin-top: 15px;
}
.ra-list-mission-body-bottom-right {
    display: flex;
    padding-top: 15px;
}
.ra-list-mission-body-bottom-right div:nth-child(2) {
    margin-left: 8px;
}
.ra-list-mission-body-bottom-right div:nth-child(2) div{
    margin-left: 9px;
    margin-top: 4px;
}
.ra-list-mission-tail {
    padding: 30px 0px;
}
.ra-list-mission-tail-ing {
}
.ra-list-mission-tail-content.main-color span{
    color: #30A9DE;
}
.ra-list-mission-tail-content:nth-child(2) {
    margin-top: 15px;
}
.ra-list-mission-tail-content span:nth-child(1) {
    margin-left: 15px;
    font-weight: 500;
}
.ra-list-mission-tail-content span:nth-child(2) {
    color: #30A9DE;
    font-weight: 600;
}
.ra-list-mission-tail-list {
    text-align: center;
}
.ra-list-mission-tail-title {
    margin-bottom: 10px;
}
.ra-list-mission-tail-point {
    font-size: 18px;
}
.ra-list-mission-tail-ing-container {
    display: flex;
}
.ra-list-mission-tail-btn-container {
    padding: 4px 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #30A9DE;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 35px;
}
.ra-list-mission-tail-btn-container:hover {
    opacity: .8;
}
.ra-list-mission-tail-btn-container.disabled {
    background: #fff;
    border: 1px solid #777;
    color: #777;
    cursor: default;
}
.ra-list-mission-tail-btn-container.disabled:hover {
    opacity: 1;
}

.ra-mission-container {
    padding: 20px 30px;
}
.ra-mission-title {
    font-size: 18px;
    color: #30A9DE;
    font-weight: 600;
}
.ra-mission-category {
    font-size: 21px;
    font-weight: 600;
    margin-top: 5px;
}
.ra-mission-status-container {
    display: flex;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
    border: 1px solid #c4c4c4;
}
.ra-mission-status-tr {
    width: 33.3%;
    display: flex;
}
.ra-mission-status-title {
    background: #f4f5f6;
    width: 45%;
    padding: 10px;
    text-align: center;
}
.ra-mission-status-content {
    width: 55%;
    padding: 10px 15px;
    position: relative;
}
.ra-mission-status-content.normal span {
    font-weight: 600;
}
.ra-mission-status-content.main-color span {
    color: #30A9DE;
    font-weight: 600;
}
.ra-mission-status-content.danger-color span {
    color: #EF5772;
    font-weight: 600;
}
.ra-mission-tabel-title-container {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ra-mission-tabel-title {
    font-size: 18px;
    font-weight: 600;
}
.ra-mission-tabel-select-container {
    width: 20%;
}

.ra-notice-line {
    width: 100%;
    height: 1px;
    background: #c4c4c4;
    margin-top: 15px;
}

.ra-back-btn {
    color: #8c8c8c;
    font-family: 'Spoqa Han Sans Neo',
    'sans-serif';
    border: 2px solid #8c8c8c;
    border-radius: 7px;
    padding: 10px 20px 6px;
    width: 125px;
    font-size: 16px;
    font-weight: 500;
}
.ra-back-btn:hover {
    color: #8c8c8c;
}
.ra-right-menu-btn-container {
    margin-bottom: 20px;
}

/* 미션 작성 */

.ra-mission-contaienr {
    width: 1000px;
    margin: 0 auto;
}
.ra-mission-caution {
    margin-top: 10px;
}
.ra-mission-caution bold {
    font-weight: 700;
    color: #333;
}
.ra-mission-caution red {
    font-weight: 700;
    color: red;
}
.ra-mission-caution undeline {
    text-decoration: underline;
}

.tutorial-wrapper {
    position: fixed;
    z-index: 1031;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
}
.tutorial-box {
    width: 25%;
}
.tutorial-container {
    background: #fff;
    width: 75%;
    height: 100%;
    padding-top: 57px;
    display: flex;
}
.tutorial-title-container {
    border-right: 1px solid #c4c4c4;
    padding: 30px;
    line-height: 30px;
    color: #777;
}
.tutorial-title-container .active {
    font-weight: 700;
    color: #000;
}
.tutorial-content-container {
    padding: 30px 0px 0px 30px;
    width: 620px;
    overflow: auto;
}
.tutorial-content-title {
    font-size: 21px;
    font-weight: 700;
}
.tutorial-content {
    line-height: 20px;
    color: #333;
}
.tutorial-content bold {
    font-weight: 700;
}
.tutorial-content red {
    color: red;
    font-weight: 700;
}
.tutorila-content-input-container {
    text-align: right;
    font-weight: 600;
}
.tutorial-content-ex-title {
    font-size: 18px;
    font-weight: 700;
}
.tutorial-keyword-box {
    font-size: 12px !important;
    padding: 0.375rem 0.65rem !important;
}
/* 프로필 */
.ra-pofile-container {
    width: 1000px;
    margin: 0 auto;
    padding: 50px 20px;
}
.ra-profile-title {
    font-size: 21px;
    font-weight: 700;
    margin-bottom: 20px;
}
.ra-pofile-wrapper{
    width: 800px;
    margin: 0 auto;
}
.ra-profile-btn {
    background: #30A9DE;
    padding: 10px 20px;
    margin-top: 20px;
    color: #fff;
    width: 96px;
    border-radius: 5px;
}

/* 테스트 */
.ra-test-description {
    font-size: 18px;
    margin-top: 10px;
}